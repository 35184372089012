@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

.boat {
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 10px;

  &:hover .header {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.4);
  }
}

.header {
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 700;
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected {
  border: 2px solid black !important;
}

.clickable {
  cursor: pointer;
}

.glanceRow {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(122px, 1fr));
  gap: 0.5em;
  padding: 6px;
}

.glanceCol {
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5em;
  flex: 1;
  padding: 6px;
}

.activity {
  text-align: right;
  font-size: 12px;
  font-weight: initial;
  display: flex;

  & > * {
    margin-left: 6px;
  }
}

.inactive {
  opacity: 0.5;
}

.manyAthletes {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  gap: 0.5em;
}

.athletes {
  flex: 1;
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
}

.athlete {
  flex: 1;
  flex-basis: max-content;
  overflow: hidden;
}

.athleteHeader {
  text-align: center;
  font-weight: 700;
  padding: 6px;
}

.hr {
  margin: 0px 6px 6px 6px !important;
}

@include media-breakpoint-up(sm) {
  .glanceCol {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }

  .glanceCol22 {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}